import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip";
import mainImg from "../images/pcv-drivers.jpg";

class JobsPage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Jobs | PCV Driver" description="PCV Driver? Find your next career at Tetley's Coaches - Leeds' oldest coach operator established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article headblock">
            <h1 className="headblock__heading">Jobs</h1>
            <p className="headblock__byline">Find a career with Tetley's Coaches</p>
          </div>
          <div className="body gutter page-article page-content-parent">
            <h2 data-aos='fade-left' data-aos-duration="500" style={{ marginBottom: 20 }}>PCV Bus &amp; Coach Driver Vacancies</h2>
            <div className="page-content">
              <div className="page-content__text" style={{ width: '100%' }}>
                <img src={mainImg} alt="PCV drivers" />
                <p>Tetley’s Coaches is currently recruiting <strong>experienced PCV bus and coach drivers</strong> to join our team at our <strong>Leeds depot</strong>. We operate a variety of <strong>private hire, contract, and school bus services</strong>, providing high-quality transport solutions across the region.</p>
                <h3>Requirements</h3>
                <br></br>
                <ul>
                  <li>Valid <strong>PCV licence</strong> with <strong>Category D entitlement</strong></li>
                  <li><strong>CPC qualification card</strong></li>
                  <li>Willingness to undergo an <strong>enhanced DBS check</strong> (required due to working with children)</li>
                </ul>

                <h3>Rate of pay:</h3>
                <br></br>

                <p>💰 <strong>Monday to Friday:</strong> £13.27 per hour <strong>+ £1 Performance Bonus + Summer Bonus = £15.27 per hour</strong></p>
                <p>💰 <strong>Saturday and Sunday:</strong> £13.77 per hour <strong>+ £2 Weekend Bonus + Summer Bonus = £16.77 per hour</strong></p>
                <p>🔹 <strong>Summer bonus scheme</strong> to run in May, June and July</p>

                <h3>Performance Bonus (£1 per hour) – Criteria for Eligibility:</h3>
                <br></br>
                <p>To qualify for the <strong>Performance Bonus</strong>, employees must consistently meet the following standards:</p>

                  <p>✅ <strong>Vehicle cleanliness</strong> – The vehicle must be cleaned, swept, and mopped at the end of the shift. (Cleaners are available Monday to Friday from 17:30 – 20:30; outside these times, drivers must sweep and mop themselves.) Litter must always be removed after every shift.</p>
                  <p>✅ <strong>Punctuality</strong> – The employee must arrive on time for work.</p>
                  <p>✅ <strong>Uniform compliance</strong> – The full company uniform (if supplied), including tie, must be worn.</p>
                  <p>✅ <strong>Vehicle care</strong> – No damage must be caused to the vehicle.</p>
                  <p>✅ <strong>Tachograph compliance</strong> – Tachograph cards must be used at all times.</p>
                  <p>✅ <strong>Availability</strong> – The employee must be available for work as requested.</p>
                  <p>✅ <strong>Attendance</strong> – The employee must not be absent from work.</p>
                  <p>✅ <strong>Customer service</strong> – No complaints must be received from customers.</p>
                  <p>✅ <strong>Walk-around checks</strong> – Must be completed via the company app.</p>
                  <p>✅ <strong>Refueling & maintenance</strong> – Diesel and AdBlue must be topped up at the end of each shift, and toilets must be emptied where applicable.</p>


                <h3>Additional Bonuses & Allowances:</h3>
                <br />
                <ul>
                  <li><strong>Rugby Jobs:</strong> £25 <strong>bonus per qualifying day</strong></li>
                  <li><strong>Football Jobs:</strong> £50 <strong>bonus per qualifying day</strong></li>
                  <li><strong>Bank Holidays:</strong> Paid at the weekend rate</li>
                  <li><strong>Boxing Day & New Year’s Day:</strong> £75 <strong>extra on top of the weekend rate</strong></li>
                  <li><strong>Overnight Allowance:</strong> £25 <strong>tax-free per night</strong></li>
                </ul>

                <h3>Contractual hours:</h3>
                <br></br>
                <p>We offer <strong>flexible working options</strong> to suit your availability:</p>
                <ul>
                  <li><strong>Full time</strong>: <strong>35 hours a week</strong> + overtime (Overtime is paid weekly)</li>
                  <li><strong>Part time</strong>: <strong>25 hours per week</strong> + overtime (Overtime is paid weekly)</li>
                  <li><strong>Casual</strong>: Zero-hour contract</li>
                </ul>


                <h3>Employee benefits:</h3>
                <br></br>
                <p>✔ <strong>Enhanced holiday pay – Workers receive their average pay over the last 52 weeks, ensuring they don’t take a pay cut while on holiday.</strong></p>
                <p>✔ <strong>Extra holiday for long service</strong> – Earn <strong>one additional day after 3 years</strong>, then <strong>one extra day per year</strong>, up to a <strong>maximum of one additional week</strong> off.</p>
                <p>✔ <strong>Paid medicals</strong></p>
                <p>✔ <strong>Annual driver CPC training</strong> (fully funded)</p>
                <p>✔ <strong>Company pension scheme</strong> – Contributions made by both employer and employee.</p>
                <p>✔ <strong>Smart uniform provided</strong></p>
                <p>✔ <strong>Modern fleet of vehicles</strong></p>

                <p>Join Tetley’s Coaches and be part of a professional team in a well-established family owned business.</p>
                {/* <p><a href="/jobs/#jobs-apply">📩 Apply today</a></p> */}
                <div id="jobs-apply" style={{ width: '100%', maxWidth: '100%' }} className="page-content__text">
                  <div className="content-box" style={{ width: '100%', maxWidth: '100%' }}>
                    <h3>How to apply</h3>
                    <p>Please complete our online <a href="https://forms.gle/xbR2G7PPAhZPLe7K8">Application form</a>.</p>
                    <p>Applicants may also email a copy of their CV to <a href="mailto:sales@tetleyscoaches.co.uk">sales@tetleyscoaches.co.uk</a> along with a cover letter.</p>
                    <p>Immediate start available.</p>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default JobsPage
